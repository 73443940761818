/* You can add global styles to this file, and also import other style files */

@import './theme/index.scss';

html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: "Open Sans", sans-serif; 
    color: $purple-base;
}

.mat-form-field-label-wrapper {
    top: -1.2em;
}
.mat-form-field-subscript-wrapper {
    height: 15px;
}

.mat-form-field {
    .mat-form-field-infix {
        padding: 8px 0 14px;
        .mat-form-field-label-wrapper {
            top: -16px;
        }
    }
    .mat-input-element {
        line-height: 20px;
    }
    &.mat-form-field-should-float label {
        font-size: 16px;
        mat-label{
            background-color: $white;
            padding: 0 5px;
            top: 3px;
            height: 30px;
            position: relative;
            display: inline-block;
        }
    }
}

.mat-card-title {
    font-weight: 400 !important;
}

.mat-card-header-text {
    margin: 0 !important;
}

@include mobile-only {
    .mat-title {
        font-size: 16px !important;
        line-height: 22px !important;
    }

    .mat-subheading-2 {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .mat-card-title {
        font-size: 14px !important;
    }
}

.mat-raised-button, .mat-flat-button{
    font-weight: 600;
}