@mixin smartphone-only {
  @media only screen and (max-width : 599px) {
    @content;
  }
}

@mixin mobile-only {
  @media only screen and (max-width : 767px) {
    @content;
  }
}

@mixin desktop-only {
  @media only screen and (min-width : $mq-tablet) {
    @content;
  }
}

@mixin less-medium {
  @media only screen and (max-width : 959px) {
    @content;
  }
}

@mixin size($width, $height:$width){
  width: $width;
  height: $height;
}