@import './rh-theme.scss';

$green-base: #42b984;
$blue-base: #3b63a2;
$blue-base-2: #4a90e2;

$purple-base: #743bbc;
$red-50: #ff6974;
$red-100: #ff4355;
$purple-base-2: #b894ec;
$white: #fff;
$white-hover: #efefef;
$gray-border: #999999;
$gray-border-stronger: #dddddd;
$gray-font: #666666;
$navbar-border: #bbb;